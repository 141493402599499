








  import { Action, Getter, Mutation } from 'vuex-class'
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import ContactTask from '../interfaces/ContactTask'
  import SigningData from '../interfaces/SigningData'
  import Icon from '@/Common/components/Icon.vue'

@Component({
  components: {
  },
})
class OneRiskWaiver extends Vue {
  @Prop({ required: true, default: null }) contactTasks: ContactTask[] | null
  @Prop({ required: false, default: null }) signingWaiver: boolean
  @Prop({ required: true, default: null }) oneRiskData: any


  @Getter('oidcStore/oidcUser') user: any
  @Getter('settings/oneRiskSettings') oneRiskSettings
  @Mutation('setPageLoading') setPageLoading

  // Data elements
  public showPad: boolean = false
  public needToScroll: boolean = true
  public newWaiver: boolean = false
  public canvas: any = null
  public signaturePad: any = null
  public signerName: string | null = null
  public agree: boolean = false
  public waiverHtmlDoc: any = null

  // Computed Data Elements

  get oneRiskUrl() {
    if (this.encodedData) {
      const d = new Date();
      const url = `${this.oneRiskSettings.oneRiskUrl}?g=${this.encodedData}&random=${d.getTime()}${Math.floor(Math.random() * 1000000)}`
      return url
    } else {
      return null
    }
  }

  get encodedData() {
    if (this.oneRiskData) {
      // Create an encoded header for the JWT (JSON Wen Token)
      // NOTE: the replaces at the end are to eliminate the padding of "=" chars at the end to make it base64URL encoding
      const header = { alg: 'HS256', typ: 'JWT' }
      const encodedHeader = Buffer.from(JSON.stringify(header))
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/\=+$/, '')

      // Encode the data passed to this component as the JWT payload
      // NOTE: the replaces at the end are to eliminate the padding of "=" chars at the end to make it base64URL encoding
      const encodedPayload = Buffer.from(JSON.stringify(this.oneRiskData))
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/\=+$/, '')

      // Create a signature for the JWT
      const crypto = require('crypto')
      const jwtSecret = this.oneRiskSettings.oneRiskSecret
      const signature = crypto
        .createHmac('sha256', jwtSecret)
        .update(encodedHeader + '.' + encodedPayload)
        .digest('base64')
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/\=+$/, '')

      // Concatentate the header, payload and signature into a JWT string
      const jwt = `${encodedHeader}.${encodedPayload}.${signature}`
      return jwt
    } else {
      return null
    }
  }

  private allContactNames(conjunction: string) {
    let names: string = ''
    let index = 0

    if (this.contactTasks) {
      this.contactTasks.forEach((ct) => {
        if (this.contactTasks) {
          if (index == this.contactTasks.length - 1 && this.contactTasks.length > 1) {
            names += ` ${conjunction} `
          } else if (index > 0) {
            names += ', '
          }
        }
        names += `${ct.contact.firstName} ${ct.contact.lastName}`
        index++
      })
    }

    return names
  }

  get currentTask() {
    if (this.contactTasks && this.contactTasks.length > 0) {
      return this.contactTasks[0].task
    }
  }

  // Life Cycle
  public mounted() {
    window.addEventListener('message', this.receiveMessage, false)
    this.setPageLoading(true)
  }

  // Methods
 

  public saveSignature() {
    // if (this.enableWetSignature && this.signaturePad.isEmpty()) {
    //   alert('Please Sign.')
    //   return
    // }
  }

  closeWindow() {
    this.$emit('closeWindow')
  }

  receiveMessage(event) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (!this.oneRiskSettings.oneRiskUrl.startsWith(event.origin)) {
      return
    }

    if (event.data) {
      try {
        const oneRiskResponseData = JSON.parse(event.data)

        switch (oneRiskResponseData.messageType) {
          case 'waiverCompleted':
            const signingData: SigningData = {
              signature: '',
              agree: true,
              signerName: this.signerName,
              waiverHTML: '',
            }
            this.$emit('save-signature', signingData)
            this.closeWindow()
            break
          case 'waiversLoaded':
            this.setPageLoading(false)
            break
          case 'allWaiversCompleted':
            this.$emit('skip-waiver')
            break
          case 'ERROR':
            alert(`ERROR CALLING 1RISK INTERFACE: ${oneRiskResponseData.status} ${oneRiskResponseData.body}`)
            console.log(`ERROR CALLING 1RISK INTERFACE: ${oneRiskResponseData.status} ${oneRiskResponseData.body}`)
            this.closeWindow()

            break
        }

      } catch (ex) {
        console.error(`ERROR: BAD REPONSE FROM 1RISK -${ex}`)
      }
    }
  }
}

export default OneRiskWaiver

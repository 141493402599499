

















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import ActivityProduct from '../../Activity/interfaces/ActivityProduct'
import ActivityTask from '../../Activity/interfaces/ActivityTask'
import ActivityService from '../../Activity/services/ActivityService'
import { activity } from '../../Activity/store'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'
import Task from '../../Task/interfaces/Task'

@Component
export default class AdminActivities extends Vue {
  @Action('activity/fetchActivities')
  private fetchActivities: any
  @Getter('activity/activities')
  private activities?: []

  @Action('task/fetchTasks')
  private fetchTasks: any
  @Getter('task/tasks')
  private tasks?: Task[]

  private currentActivity: Activity | null = null
  public currentActivityAvailableTasks: Task[] = []
  private newActivityValid: any = false
  private updatingActivity: boolean = false
  private deletingActivity: boolean = false
  private deletingTask: number = -1
  private linkingTask: boolean = false

  $refs: {
    newActivityForm: HTMLFormElement
  }

  onActivitySelected({ item: selActivity, value: selected }) {
    if (selActivity && selected) {
      const editActivity = {
        ...selActivity,
      }
      Vue.nextTick(() => {
        this.currentActivity = editActivity
      })
    } else {
      this.currentActivity = null
    }
  }

  private newActivityOptions = {
    initialValidation: 'none',
  }

  private activitySchema = {
    required: ['name'],
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
        default: '',
      },
      ageMin: {
        title: 'Age Min',
        type: 'number',
      },
      ageMax: {
        title: 'Age Max',
        type: 'number',
      },
      imageUrl: {
        title: 'Image Url',
        type: 'string',
      },
      activityTasks: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
        items: {},
      },
      activityProducts: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
        items: {},
      },
    },
  }

  public newActivity: Activity = {
    id: 0,
    name: '',
    ageMin: null,
    ageMax: null,
    imageUrl: '',
    isLocationDefaultActivity: false,
    activityTasks: [],
    activityProducts: [],
  }

  public clearedActivity = { ...this.newActivity }
  public newActivityTask: ActivityTask = {
    id: 0,
    activityId: 0,
    taskId: 0,
  }

  public newActivityProduct: ActivityProduct = {
    id: 0,
    activityId: 0,
    productId: '',
  }

  public created() {
    if (!this.activities) {
      this.getActivities()
    }

    if (!this.tasks) {
      this.getTasks()
    }
  }

  public async getActivities() {
    await this.fetchActivities()
    if (this.currentActivity && this.activities) {
      const thisActivity = this.activities.find((l: Location) => l.id == this.currentActivity!.id)
      if (thisActivity) {
        this.currentActivity = thisActivity
      }
    }
  }

  public getTasks() {
    this.fetchTasks()
  }

  public viewActivityDetails(activity) {
    this.currentActivity = activity
    if (this.tasks) {
      this.currentActivityAvailableTasks = this.tasks.filter((elem) =>
        activity.activityTasks.find(({ taskId }) => elem.id === taskId)
      )
    }
  }

  private async addActivity() {
    this.$refs.newActivityForm.validate()

    if (this.newActivityValid) {
      await ActivityService.AddActivity(this.newActivity)
      this.getActivities()
      this.newActivity = { ...this.clearedActivity }
      this.$refs.newActivityForm.reset()
    }
  }

  private async updateActivity() {
    if (this.currentActivity) {
      this.updatingActivity = true
      await ActivityService.UpdateActivity(this.currentActivity)
      this.getActivities()
      this.updatingActivity = false
    }
  }

  private async removeActivityTask(activityId: number, activityTaskId: number) {
    this.deletingTask = activityTaskId
    await ActivityService.RemoveActivityTask(activityId, activityTaskId)
    this.getActivities()
    this.deletingTask = -1
  }

  private async removeActivity() {
    if (this.currentActivity) {
      this.deletingActivity = true
      await ActivityService.RemoveActivity(this.currentActivity.id)
      this.getActivities()
      this.deletingActivity = false
      this.currentActivity = null
    }
  }

  private async addActivityTask() {
    if (this.currentActivity) {
      this.linkingTask = true
      this.newActivityTask.activityId = this.currentActivity.id
      await ActivityService.AddActivityTask(this.newActivityTask)
      this.getActivities()
      this.linkingTask = false
    }
  }

  get activitiesTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: 'Age Min', value: 'ageMin', align: 'center' },
      { text: 'Age Max', value: 'ageMax', align: 'center' },
      { text: 'Image', value: 'imageUrl', align: 'center', sortable: false },
      { text: 'Modified Date', value: 'modifiedDate' },
      { text: 'Status', value: 'hasTasks', align: 'center', sortable: false },
    ]
  }
}

<template>
  <div class="loading-page">
    <img src="/img/double-ring-loader.svg" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions({
      oidcSignInCallback: 'oidcStore/oidcSignInCallback',
    }),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        // Either go tot he default or a specific route in local storage
        this.$router.push(localStorage.getItem('redirectPath') || redirectPath)
      })
      .catch((err) => {
        console.error(err)
        this.$router.push('/oidc-callback-error').catch(() => {
          // ... No catch at this time
        }) // Handle errors any way you want
      })
  },
}
</script>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(21, 27, 34);

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.035;
    background: url('/img/bkg--mountains-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>

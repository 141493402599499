































































import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'
import WaiverReleaseOfReliability from '../interfaces/WaiverReleaseOfReliability'
import SigningData from '../interfaces/SigningData'
import SignaturePad from 'signature_pad'
import Icon from '@/Common/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
class SignWaiver extends Vue {
  @Prop({ required: true, default: null }) waiver: WaiverReleaseOfReliability | null
  @Prop({ required: true, default: null }) contactTasks: ContactTask[] | null
  @Prop({ required: true, default: true }) enableWetSignature: boolean
  @Prop({ required: true, default: true }) enableAgreeCheckbox: boolean
  @Prop({ required: true, default: true }) enableTextSignature: boolean
  @Prop({ required: false, default: null }) affirmationStatement: string | null
  @Prop({ required: false, default: null }) signingWaiver: boolean

  @Getter('oidcStore/oidcUser') user: any

  // Data elements
  public showPad: boolean = false
  public needToScroll: boolean = true
  public newWaiver: boolean = false
  public canvas: any = null
  public signaturePad: any = null
  public signerName: string | null = null
  public agree: boolean = false
  public waiverHtmlDoc: any = null

  // Computed Data Elements
  get affirmationHtml() {
    if (this.affirmationStatement) {
      return this.affirmationStatement
    } else {
      let text = `I affirm that I am <span class="contact-names">${this.allContactNames(
        'or'
      )}</span>, a parent or legal guardian, or otherwise legally authorized to sign on their behalf.`
      text += `&nbsp;&nbsp;If you are not authorized to sign for this person, skip this waiver.`
      return text
    }
  }

  private allContactNames(conjunction: string) {
    let names: string = ''
    let index = 0

    if (this.contactTasks) {
      this.contactTasks.forEach((ct) => {
        if (this.contactTasks) {
          if (index == this.contactTasks.length - 1 && this.contactTasks.length > 1) {
            names += ` ${conjunction} `
          } else if (index > 0) {
            names += ', '
          }
        }
        names += `${ct.contact.firstName} ${ct.contact.lastName}`
        index++
      })
    }

    return names
  }

  get currentTask() {
    if (this.contactTasks && this.contactTasks.length > 0) {
      return this.contactTasks[0].task
    }
  }

  get signNamePlaceholder() {
    if (this.contactTasks && this.contactTasks.length > 1) {
      // Signing for multiple contacts, use the logged in user's name
      return `Type: ${this.user.name}`
    } else if (this.contactTasks && this.contactTasks.length == 1) {
      if (this.contactTasks && this.contactTasks[0].contact.age < 18) {
        // Signing for a single minor, use logged in user's name
        return `Type: ${this.user.name}`
      } else {
        // Signing for single adult, use the participant's name
        return `Type: ${this.contactTasks[0].contact.firstName} ${this.contactTasks[0].contact.lastName}`
      }
    }
  }

  // Life Cycle
  public mounted() {
    if (this.enableWetSignature) {
      this.canvas = this.$refs.canvas
      this.signaturePad = new SignaturePad(this.canvas)
      this.resizeCanvas()
    }
  }

  // Methods
  public skipWaiver() {
    this.clearSignature()
    this.signerName = null
    this.agree = false
    this.$emit('skip')
  }

  public saveSignature() {
    if (this.enableWetSignature && this.signaturePad.isEmpty()) {
      alert('Please Sign.')
      return
    }

    if (this.enableAgreeCheckbox && !this.agree) {
      alert('Please check to agree.')
      return
    }

    if (this.enableTextSignature) {
      if (this.signerName == null) {
        alert(`Please type your name in the provided box.`)
        return
      } else if (this.signerName.trim() != this.signNamePlaceholder!.replace('Type: ', '')) {
        alert(`Please sign ${this.signNamePlaceholder} exactly.`)
        return
      }
    }

    // Get the wet signature
    let newSignature = ''
    if (this.enableWetSignature) {
      newSignature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '')
    }

    // We need to create a new HTML doc with the waiver page exactly as it appears to the signer and store this in RTP
    // Get a reference to the top <div> element
    let waiverHtmlText: string | null = null
    const waiverElement = document.getElementById('waiver-container')

    if (waiverElement) {
      // Create a new HTML document object
      this.waiverHtmlDoc = document.implementation.createHTMLDocument('Waiver')
      // Insert all the HTML from the waiver page into the new document
      const waiverDiv = this.waiverHtmlDoc.createElement('div')
      waiverDiv.innerHTML = waiverElement.innerHTML

      try {
        this.waiverHtmlDoc.body.appendChild(waiverDiv)
      } catch (e) {
        console.log(e)
      }

      // Recursively walk through the waiver HTML and copy styles from each element
      // to the corresponding element in the new document object
      this.walkTheDOM(waiverDiv, this.copyStyles)

      // Once the styles have been copied, serialize the HTML and add to the signing data
      // The parent component will store this as the ROL Text to be stored in RTP
      waiverHtmlText = new XMLSerializer().serializeToString(this.waiverHtmlDoc)
    }

    const signingData: SigningData = {
      signature: newSignature,
      agree: this.agree,
      signerName: this.signerName,
      waiverHTML: waiverHtmlText,
    }

    this.$emit('save-signature', signingData)

    this.clearSignature()
  }

  public clearSignature() {
    if (this.enableWetSignature && this.signaturePad) {
      this.signaturePad.clear()
    }

    if (this.enableAgreeCheckbox) {
      this.agree = false
    }

    if (this.enableTextSignature) {
      this.signerName = null
    }
  }

  public resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    this.canvas.width = this.canvas.offsetWidth * ratio
    this.canvas.height = this.canvas.offsetHeight * ratio
    this.canvas.getContext('2d').scale(ratio, ratio)
    this.signaturePad.clear() // otherwise isEmpty() might return incorrect value
  }

  // This function grabs all the styles for an HTML element and builds a CSS style declaration string for them
  public dumpCSSText(id) {
    let cssText = ''
    const orginalElement = document.getElementById(id) as HTMLElement
    if (orginalElement) {
      const elementStyles = getComputedStyle(orginalElement, '')
      const elementKeys = Object.keys(elementStyles)

      for (const key in elementKeys) {
        if (elementStyles[key]) {
          const propertyValue = elementStyles.getPropertyValue(elementStyles[key])
          if (propertyValue) {
            cssText += elementStyles[key] + ':' + elementStyles.getPropertyValue(elementStyles[key]) + ';'
          }
        }
      }
    }

    return cssText
  }

  // This function recursively walks through an HTML structure and calls a function for each
  public walkTheDOM(node, func) {
    func(node)
    node = node.firstChild
    while (node) {
      this.walkTheDOM(node, func)
      node = node.nextSibling
    }
  }

  // This method copies styles from a node in the DOM to the curresponding element of the new documnt object
  public copyStyles(node) {
    if (node.id && node.id != 'waiver-snapshot') {
      const foundElement = this.waiverHtmlDoc.getElementById(node.id)
      if (foundElement) {
        const styles = this.dumpCSSText(node.id)
        if (styles) {
          foundElement.style.cssText = styles
        }
      }
    }
  }
}

export default SignWaiver

































































































































































import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Emit, Model, PropSync } from 'vue-property-decorator'
import ContactTaskBase from './ContactTaskBase'
import ContactTask from '../interfaces/ContactTask'
import TaskService from '../services/TaskService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import RentalProfileInterface from '../interfaces/RentalProfile'
import AxSelect from '@/Common/components/AxSelect.vue'
import Icon from '@/Common/components/Icon.vue'
import RentalProfile from '../models/RentalProfile'
import { required } from 'vuelidate/lib/validators'

@Component({
  components: {
    AxSelect,
    Icon,
  },
})
class RentalProfileForm extends ContactTaskBase {
  @Prop({ required: true, default: false }) isReadOnly: boolean
  @Prop({ required: true, default: false }) isHidden: boolean
  @Prop({ required: true, default: false }) isUpdating: boolean
  @Prop({ required: true, default: false }) enableShoeSize: boolean
  @Prop({ required: true, default: false }) enableStance: boolean
  @Prop({ required: true, default: null }) profile: RentalProfile | null

  @Getter('settings/languageSettings') language

  protected isMetric: boolean = false
  protected editProfile: RentalProfile | null = null
  protected goofy: boolean = false

  created() {
    this.editProfile = new RentalProfile(this.profile)
    this.goofy = this.editProfile.selectedGoofy === 'Y'
  }

  validations() {
    return {
      editProfile: {
        selectedAbility: { required },
        selectedAge: { required },
        selectedHeight: { required },
        selectedWeight: { required },
      },
    }
  }

  private getValidationErrors(profileKey) {
    const errors: any[] = []
    if (!this.$v!.editProfile![profileKey]!.$dirty) {
      return errors
    }
    if (!this.$v!.editProfile![profileKey]!.required) {
      errors.push('This is required.')
    }
    return errors
  }

  private isRentalProfileComplete(profile) {
    return false
  }

  get hasProfile() {
    return this.profile!.selectedAbility
  }

  get cancelLabel() {
    return this.showSkipButton ? 'Skip' : 'Cancel'
  }

  get updateLabel() {
    return !this.showCancelButton && !this.showSkipButton ? 'Continue' : 'Update'
  }

  get showSkipButton() {
    if (!this.isRentalProfileComplete(this.editProfile) && this.contactTask.isOptional) {
      return true
    }
    return false
  }

  get showCancelButton() {
    return this.hasProfile
  }

  get profileAbility() {
    const value = this.profile!.abilityRefs.find((r) => r.listKey.toString() === this.profile!.selectedAbility)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileHeight() {
    const value = this.profile!.heightRefs.find((r) => r.listKey.toString() === this.profile!.selectedHeight)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileWeight() {
    const value = this.profile!.weightRefs.find((r) => r.listKey.toString() === this.profile!.selectedWeight)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get profileAge() {
    const value = this.profile!.ageRefs.find((r) => r.listKey.toString() === this.profile!.selectedAge)
    if (value) {
      return value.listDescription
    }

    return null
  }

  get abilityValues() {
    return this.profile
      ? this.profile.abilityRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: v.listDescription,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get heightValues() {
    return this.profile
      ? this.profile.heightRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            const heightV = v.listDescription
            const heightEngV = `${heightV
              .split('/')[0]
              .trim()
              .replace('.', `'`)
              .replace(' - ', '" - ')
              .replace('.', `'`)}"`
            const heightMetV = heightV.split('/')[1].trim()
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: this.isMetric ? heightMetV : heightEngV,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get weightValues() {
    return this.profile
      ? this.profile.weightRefs
          .filter((r) => r.dinLookupValue > -1)
          .map((v) => {
            const weightV = v.listDescription
            const weightEngV = weightV.split('/')[0].trim()
            const weightMetV = weightV.split('/')[1].trim()
            return {
              dinLookupValue: v.dinLookupValue,
              listDescription: this.isMetric ? weightMetV : weightEngV,
              listKey: v.listKey.toString(),
            }
          })
      : null
  }

  get skierAbilityUrl() {
    if (this.language && this.language.rentalProfile) {
      return this.language.rentalProfile.skierAbilityUrl
    }
  }

  get skierAbilityText() {
    if (this.language && this.language.rentalProfile) {
      return this.language.rentalProfile.skierAbilityText
    }
  }

  async completeRental() {
    this.$v!.$touch()

    if (!this.$v!.$error) {
      if (this.editProfile) {
        this.editProfile.selectedGoofy = this.goofy ? 'Y' : 'N'
      }

      // Update the related object with the information on the form
      if (this.contactTask) {
        const newRelatedObject = {
          ...this.contactTask.relatedObject,
          ...this.editProfile,
        }
        this.contactTask.relatedObject = newRelatedObject
        this.$emit('onCompleted', this.contactTask)
      }
    }
  }

  @Emit('onSkipped')
  async skip() {
    return this.contactTask
  }

  @Emit('onEdit')
  async edit() {
    return this.contactTask
  }

  @Emit('onCancelEdit')
  async cancel() {
    this.editProfile = new RentalProfile(this.profile)
    this.contactTask.isComplete = true
    return this.contactTask
  }
}

export default RentalProfileForm

import Logout from './views/Logout.vue'
import OidcCallback from './views/OidcCallback.vue'
import SampleLoading from './views/SampleLoading.vue'
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

const routes: any[] = [
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      isPublic: true,
      gtm: 'Logout',
    },
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-callback-error',
    name: 'oidcCallbackError',
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidcSilentRenew',
    meta: {
      isPublic: true,
    },
    beforeEnter: () => {
      vuexOidcProcessSilentSignInCallback()
    },
  },
]

export default routes

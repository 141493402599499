






























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ReportService from '../../Reports/services/ReportService'
import ContactEntitlementReportRequest from '../../Reports/interfaces/ContactEntitlementReportRequest'
import Location from '../../Location/interfaces/location'
import store from '@/Common/store'
import axios from 'axios'
import CustomerEntitlementsReportRequest from '../../Reports/interfaces/CustomerEntitlementsReportRequest'
import ContactEntitlementReportStatus from '../../Reports/interfaces/ContactEntitlementReportStatus'

@Component
export default class AdminReporting extends Vue {
  @Action('location/fetchLocations')
  private fetchLocations: any
  @Getter('location/locations')
  private locations?: []
  @Getter('exportKey') exportKey

  private saving: boolean = false
  private reRunningReport: boolean = false
  private downloadingCustomerEntitlementsReport: boolean = false

  private startDate = new Date().toISOString().substr(0, 10)
  private startDateFormatted = this.formatDate(this.startDate)
  private startDateMenu = false

  private endDate = new Date().toISOString().substr(0, 10)
  private endDateFormatted = this.formatDate(this.endDate)
  private endDateMenu = false
  private status: ContactEntitlementReportStatus | null = null

  private request: ContactEntitlementReportRequest = {
    locationId: 1,
    startDate: new Date(),
    endDate: new Date(),
    includePrintableMedia: true,
    isCsvExport: true,
    includeDetails: true,
  }

  private customerEntitlementsRequest: CustomerEntitlementsReportRequest = {
    ipCodesCommaSeparated: '',
  }

  // Methods
  public async created() {
    if (!this.locations) {
      this.getLocations()
    }
    this.status = await ReportService.GetCustomerReportStatus()
  }

  private async reRunReport() {
    this.reRunningReport = true
    await ReportService.ReRunCustomerReport(this.exportKey)
    await new Promise((resolve) => setTimeout(resolve, 5000))
    this.status = await ReportService.GetCustomerReportStatus()
    this.reRunningReport = false
  }
  private async getCustomerReport() {
    this.saving = true
    const request = this.request
    const fileDownloadName = `arrival-customer-report-${
      request.locationId
    }-location-${request.startDate.toDateString()}-to-${request.endDate.toDateString()}-${
      request.includePrintableMedia ? 'include-printable-media' : 'exclude-printable-media'
    }${request.isCsvExport ? '.csv' : '.json'}`
    const response = await ReportService.GetCustomerReport(request)

    let data = response
    if (!request.isCsvExport) {
      data = JSON.stringify(response)
    }

    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileDownloadName)
    document.body.appendChild(link)
    link.click()
    this.saving = false
  }

  private async getCustomerEntitlementsReport() {
    this.downloadingCustomerEntitlementsReport = true
    const response = await ReportService.GetCustomerEntitlementsReport(this.customerEntitlementsRequest)

    const url = window.URL.createObjectURL(new Blob([JSON.stringify(response)]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'customer-entitlements-report.json')
    document.body.appendChild(link)
    link.click()

    this.downloadingCustomerEntitlementsReport = false
  }

  private isLocationDisabled(item: Location) {
    return item.usePosTransactionsAndOrders == false
  }

  private getLocationText(item: Location) {
    if (item.usePosTransactionsAndOrders) {
      return item.name
    } else {
      return item.name + ' (POS disabled for this location)'
    }
  }

  public async getLocations() {
    await this.fetchLocations()
  }

  private formatDate(date) {
    if (!date) {
      return null
    }
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }

  private parseDate(date) {
    if (!date) {
      return null
    }
    const [month, day, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  // Computed
  get computedStartDateFormatted() {
    return this.formatDate(this.startDate)
  }

  get computedEndDateFormatted() {
    return this.formatDate(this.endDate)
  }

  // Watches
  @Watch('startDate')
  onStartDateChangedChanged(value: string) {
    this.startDateFormatted = this.formatDate(this.startDate)
    const stringValue: string | null = this.startDateFormatted
    if (stringValue) {
      this.request.startDate = new Date(stringValue)
    }
  }
  @Watch('endDate')
  onEndDateChangedChanged(value: string) {
    this.endDateFormatted = this.formatDate(this.endDate)
    const stringValue: string | null = this.endDateFormatted
    if (stringValue) {
      this.request.endDate = new Date(stringValue)
    }
  }
}

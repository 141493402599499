<template>
  <div class="logout-page">
    <h1>Logging out...</h1>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$router.push('/').catch(() => {
      // ... No catch at this time
    })
  },
}
</script>

<style lang="scss" scoped>
.logout-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(21, 27, 34);

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.035;
    background: url('/img/bkg--mountains-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  h1 {
    font-size: 0;
  }
}
</style>

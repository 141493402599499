import type ActivityContact from '@/Activity/interfaces/ActivityContact'
import ArrivalService from '@/Common/services/ArrivalService'
import ContactTask from '../interfaces/ContactTask'
import ContactTaskGrouping from '../interfaces/ContactTaskGrouping'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import Task from '../interfaces/Task'
import TaskType from '../interfaces/TaskType'
import store from '@/Common/store'

class TaskService extends ArrivalService {
  public static async AddTask(task: Task): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/tasks`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: task,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateTask(task: Task): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/tasks/${task.id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: task,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async RemoveTask(taskId: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/tasks/${taskId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async AddTaskType(taskType: TaskType): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/tasktypes`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: taskType,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async RemoveTaskType(taskTypeId: number): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'DELETE',
        url: `${baseUrl}/api/tasktypes/${taskTypeId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async UpdateTaskType(taskType: TaskType): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'PUT',
        url: `${baseUrl}/api/tasktypes/${taskType.id}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: taskType,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchTaskTypes(): Promise<TaskType[]> {
    let result: any = []
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/tasktypes`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchTasks(): Promise<Task[]> {
    let result: any = []
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/tasks`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async FetchContactTasks(
    activityContacts: ActivityContact[],
    locationId: number
  ): Promise<ContactTaskGrouping> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/tasks/ContactTaskGrouping/Location/${locationId}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: activityContacts,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async CompleteContactTask(request: ContactTask): Promise<GenericResponse> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/tasks/contact/complete${request.completeEndpoint}`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: request,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const err = error as any
      const serviceError: ServiceError = {
        error: err.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }
}

export default TaskService

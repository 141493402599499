


















































































































































































































import { Action, Getter } from 'vuex-class'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import ContactTaskBase from './ContactTaskBase'
import ContactTask from '../interfaces/ContactTask'
import TaskService from '../services/TaskService'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import Contact from '@/Common/interfaces/Contact'
import ChildRegProfile from '../models/ChildRegProfile'
import ChildRegProfileInterface from '../interfaces/ChildRegProfile'
import Icon from '@/Common/components/Icon.vue'
import { required } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'

@Component({
  components: {
    Icon,
    VueTelInput,
  },
})
class ChildRegistrationForm extends ContactTaskBase {
  @Prop() childRegProfile: ChildRegProfileInterface | null
  @Prop() copyFromContactTask: ContactTask | null
  @Prop({ required: true, default: false }) isHidden: boolean
  @Prop({ required: true, default: false }) isReadOnly: boolean
  @Prop({ required: true, default: false }) isUpdating: boolean

  @Getter('oidcStore/oidcUser') user

  // Data Values
  private newChildRegProfile: ChildRegProfile | null = null
  private defaultTextValue = 'None'

  public created() {
    const initialChildRegProfile = {
      ...this.childRegProfile,
      primaryEmergencyContact: `${this.user.given_name} ${this.user.family_name}`,
    }
    this.newChildRegProfile = new ChildRegProfile(initialChildRegProfile)
  }

  validations() {
    return {
      newChildRegProfile: {
        primaryEmergencyContact: { required },
        primaryEmergencyPhone: { required },
        alternateEmergencyContact1: { required },
        alternateEmergencyPhone1: { required },
      },
    }
  }

  get hasProfile() {
    return this.childRegProfile && this.childRegProfile.primaryEmergencyContact
  }

  get telephoneDropdownOptions() {
    return { disabledDialCode: false, tabindex: -1 }
  }

  get telephoneInputOptions() {
    return { showDialCode: false, tabindex: 0 }
  }

  // Computed Values
  get showSkipButton() {
    if (!this.hasProfile && this.contactTask.isOptional) {
      return true
    }
    return false
  }

  get showCancelButton() {
    return this.hasProfile
  }

  get cancelLabel() {
    return this.showSkipButton ? 'Skip' : 'Cancel'
  }

  get updateLabel() {
    return !this.showCancelButton && !this.showSkipButton ? 'Continue' : 'Update'
  }

  // Methods
  @Emit('onCompleted')
  async completeRegistration() {
    if (!this.newChildRegProfile!.drugAllergy) {
      this.newChildRegProfile!.drugAllergy = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.foodAllergy) {
      this.newChildRegProfile!.foodAllergy = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.medication) {
      this.newChildRegProfile!.medication = this.defaultTextValue
    }
    if (!this.newChildRegProfile!.specialCondition) {
      this.newChildRegProfile!.specialCondition = this.defaultTextValue
    }

    this.$v!.$touch()

    // No Errors
    if (!this.$v!.$error) {
      // Update the related object with the information on the form
      if (this.contactTask) {
        const newRelatedObject = {
          ...this.contactTask.relatedObject,
          ...this.newChildRegProfile,
        }
        this.contactTask.relatedObject = newRelatedObject

        return this.contactTask
      }
    }

    return null
  }

  @Emit('onSkipped')
  async skip() {
    this.newChildRegProfile = new ChildRegProfile(this.childRegProfile)
    return this.contactTask
  }

  @Emit('onEdit')
  async edit() {
    return this.contactTask
  }

  @Emit('onCancelEdit')
  async cancel() {
    this.newChildRegProfile = new ChildRegProfile(this.childRegProfile)
    return this.contactTask
  }

  private getErrorMessages(vuelidateObject, message) {
    return vuelidateObject.$error ? [`${message}`] : []
  }

  private clearNone(key) {
    if (!this.newChildRegProfile![key] || this.newChildRegProfile![key].toUpperCase() === 'NONE') {
      Vue.set(this.newChildRegProfile!, key, '')
    }
  }

  private addNone(key) {
    if (!this.newChildRegProfile![key]) {
      Vue.set(this.newChildRegProfile!, key, 'None')
    }
  }

  private dataChanged() {
    // If any of the data on the screen was populated, then
    if (
      this.newChildRegProfile!.primaryEmergencyContact ||
      this.newChildRegProfile!.primaryEmergencyPhone ||
      this.newChildRegProfile!.alternateEmergencyContact1 ||
      this.newChildRegProfile!.alternateEmergencyPhone1 ||
      this.newChildRegProfile!.drugAllergy ||
      this.newChildRegProfile!.foodAllergy ||
      this.newChildRegProfile!.medication ||
      this.newChildRegProfile!.specialCondition
    ) {
      return true
    }
    return false
  }
}

export default ChildRegistrationForm

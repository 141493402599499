




import { Component, Vue } from 'vue-property-decorator'

export default {
  name: 'system',
  components: {},
}
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminEmailAccess from '../interfaces/AdminEmailAccess'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminAccess extends Vue {

  @Action('admin/fetchAdminEmailAccess')
  private fetchAdminEmailAccess: any

  private newAdminEmailValid: any = false
  private currentAdminEmailValid: any = false

  $refs: {
    newEmailForm: HTMLFormElement
  }

  // Life Cycle
  public created() {
    this.getEmailAccessRecords()
  }

  // Watchers
  @Watch('selectedEmails')
  onSelectedEmailsChanged(value: AdminEmailAccess[] | null) {
    if (value && value.length > 0) {
      const firstValue = value[0]
      this.currentEmail = {
        ...firstValue,
      }
    } else {
      this.currentEmail = null
    }
  }

  // Data Elements
  private emailAccessRecords: null = null
  private selectedEmails: [] = []
  private currentEmail: AdminEmailAccess | null = null

  private newAdminEmailAccess: AdminEmailAccess | null = {
    id: 0,
    email: '',
    createdDateUtc: new Date(),
    modifiedDateUtc: new Date(),
    isDeleted: false,
  }

  private emailFormOptions = {
    initialValidation: 'none',
  }

  private adminEmailSchema = {
    required: ['email'],
    properties: {
      id: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      email: {
        title: 'Email',
        type: 'string',
        default: '',
      },
    },
  }

  // Computed Values
  get emailsTableHeaders() {
    return [
      { text: 'Email', value: 'email' },
      { text: '', value: '' },
    ]
  }

  // Methods
  public async getEmailAccessRecords() {
    this.emailAccessRecords = await this.fetchAdminEmailAccess()
  }

  private async addEmailAccess() {
    this.$refs.newEmailForm.validate()

    if (this.newAdminEmailValid) {
      await AdminService.AddAdminEmailAccess(this.newAdminEmailAccess)
      await this.getEmailAccessRecords()
      this.$refs.newEmailForm.reset()
    }
  }

  private async removeEmailAccess() {
    if (this.currentEmail) {
      await AdminService.DeleteAdminEmailAccess(this.currentEmail.id)
      this.getEmailAccessRecords()
      this.currentEmail = null
    }
  }

  public viewEmailDetails(email: AdminEmailAccess) {
    this.currentEmail = email
  }
}

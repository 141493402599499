


































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Task from '../../Task/interfaces/Task'
import TaskService from '../../Task/services/TaskService'
import TaskType from '../../Task/interfaces/TaskType'

@Component
export default class AdminTaskTypes extends Vue {
  @Action('task/fetchTaskTypes')
  private fetchTaskTypes: any
  @Getter('task/taskTypes')
  private taskTypes?: []

  // Life Cycle
  public created() {
    if (!this.taskTypes || this.taskTypes.length == 0) {
      this.getTaskTypes()
    }
  }

  // Watchers
  @Watch('selectedTaskTypes')
  onSelectedTaskTypesChanged(value: TaskType[] | null) {
    if (value && value.length > 0) {
      const firstValue = value[0]
      this.currentTaskType = {
        ...firstValue,
      }
    } else {
      this.currentTaskType = null
    }
  }

  // Data Elements
  private selectedTaskTypes: [] = []
  public currentTaskType: TaskType | null = null

  private taskTypeSchema = {
    properties: {
      id: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        type: 'string',
        readOnly: true,
        'x-display': 'hidden',
      },
      isDeleted: {
        type: 'boolean',
        readOnly: true,
        'x-display': 'hidden',
      },
      enableShoeSize: {
        title: 'Enable Shoe Size',
        type: 'boolean',
        'x-display': 'switch',
      },
      enableStance: {
        title: 'Enable Stance',
        type: 'boolean',
        'x-display': 'switch',
      },
      effectiveDays: {
        title: 'Effective Days',
        type: 'number',
      },
    },
  }

  // Computed Values
  get taskTypesTableHeaders() {
    return [
      { text: 'Name', value: 'name' },
      { text: '', value: '' },
    ]
  }

  // Methods
  public viewTaskTypeDetails(taskType: TaskType) {
    this.currentTaskType = taskType
  }

  public getTaskTypes() {
    this.fetchTaskTypes()
  }

  public async updateTaskType() {
    if (this.currentTaskType) {
      await TaskService.UpdateTaskType(this.currentTaskType)
      this.getTaskTypes()
      this.currentTaskType = null
    }
  }

  public async removeTaskType() {
    if (this.currentTaskType) {
      await TaskService.RemoveTaskType(this.currentTaskType.id)
      this.getTaskTypes()
      this.currentTaskType = null
    }
  }
}

























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ApiKey from '../../Api/interfaces/ApiKey'
import ApiService from '../../Api/services/ApiService'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminApi extends Vue {
  private saving: boolean = false
  private showCreateTokenForm: boolean = false
  private panel = [0]
  private newApiKey: ApiKey = {
    id: null,
    createdDateUtc: new Date(),
    token: null,
    nickname: '',
    type: 'standard',
  }
  private apiKeys: ApiKey[] = []
  private settings: AdminSetting | null = null

  // Methods
  public created() {
    this.getApiKeys()
    this.getSettings()
  }

  private async getApiKeys() {
    this.apiKeys = await ApiService.FetchApiKeys()
  }

  private async getSettings() {
    this.settings = await AdminService.FetchAdminSettings()
  }

  private async updateSettings() {
    if (this.settings) {
      await AdminService.UpdateAdminSettings(this.settings)
      await this.getSettings()
    }
  }

  private async addApiKey() {
    await ApiService.CreateApiKey(this.newApiKey)
    await this.getApiKeys()
    this.newApiKey.nickname = ''
    this.showCreateTokenForm = false
  }

  private async removeApiKey(id: number) {
    await ApiService.RemoveApiKey(id)
    await this.getApiKeys()
  }

  private toggleShowCreateTokenForm() {
    this.showCreateTokenForm = !this.showCreateTokenForm
  }
}

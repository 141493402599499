










































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ required: true, default: '' }) type: string
  @Prop({ required: true, default: 'black' }) color: string
}












import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class AppFooter extends Vue {
  @Getter('settings/languageSettings') language
}

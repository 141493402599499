





















import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class Welcome extends Vue {
  @Getter('settings/theme') theme
  @Getter('settings/languageSettings') language

  public signingIn: boolean = false

  public onSignIn() {
    this.signingIn = true
    this.$router.push({ name: 'home' }).catch(() => {
      // ... No catch response yet
    })
  }

  get logoStyle() {
    if (this.theme && this.theme.general && this.theme.general.logoUrl && this.theme.general.logoWelcomeWidth) {
      return `background-image: url('${this.theme.general.logoUrl}');width: ${this.theme.general.logoWelcomeWidth}px;`
    }

    return ''
  }

  get titleClasses() {
    const classes: string[] = []
    if (this.theme && this.theme.general && this.theme.general.showArrivalTitleAsImage) {
      classes.push('bgImage')
    }
    return classes.join(' ')
  }

  get titleStyle() {
    if (this.theme && this.theme.general && this.theme.general.showArrivalTitleAsImage) {
      return `background-image: url('${this.theme.general.arrivalTitleImageUrl}');`
    }

    return ''
  }
}

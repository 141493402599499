





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminGeneralSettings extends Vue {
  @Action('admin/fetchAdminSettings')
  private fetchAdminSettings: any

  private allSettings: object | null = null
  private generalSettings: object | null = null
  private saving: boolean = false

  private schema: any = {
    properties: {
      isSkiDataEnabled: {
        type: 'boolean',
        title: 'Display External Order Id As QR Code',
        'x-display': 'switch',
        description: 'Required to print media for SkiData. If not selected, Customer ID will be used.',
      },
      googleAnalyticsTagId: {
        type: 'string',
        default: '',
        title: 'Google analytics tag id',
      },
      resortCodes: {
        type: 'string',
        title: 'Resort codes',
        default: '',
        description: 'Enter one or more resort codes (comma separated)',
      },
      resortGroupCodes: {
        type: 'string',
        title: 'Resort group codes',
        default: '',
        description: 'Enter one or more resort group codes (comma separated)',
      },
      salesLocationIds: {
        type: 'string',
        title: 'Sales location ids',
        default: '',
        description: 'Enter one or more location ids (comma separated)',
      },
      passCheckerLocationId: {
        type: 'string',
        default: '',
        title: 'Pass Checker POS Location Id',
      },
      oneRiskUrl: {
        type: 'string',
        default: '',
        title: '1Risk URL',
      },
      oneRiskSecret: {
        type: 'string',
        default: '',
        title: '1Risk Secret',
      },
      dateFormat: {
        title: '1Risk Date Format',
        type: 'string',
        default: 'mmddyyyy',
        description: 'Preferred Date Format',
        enum: ['mmddyyyy', 'ddmmyyyy'], 
      },
      pointOfSaleSystem: {
        type: 'string',
        default: 'RTP',
        title: 'Point of Sale System',
        description: 'Point of Sale System',
        enum: ['RTP', 'SW'],
      },
      cookieConsentScript: {
        type: 'string',
        default: '',
        title: 'Cookie Consent Script',
      },
      cookieConsentStyles: {
        type: 'string',
        default: '',
        title: 'Cookie Consent Stylesheet',
      }
    },
  }
  private notification: any = {
    visible: false,
    title: '',
    text: '',
    icon: '',
    color: null,
    timeout: 2000,
  }

  public created() {
    this.fetchSettings()
  }

  private async fetchSettings() {
    this.allSettings = await this.fetchAdminSettings()
    this.generalSettings = {
      ...this.allSettings,
    }
  }

  private async updateSettings() {
    if (this.allSettings) {
      this.saving = true
      const newSettings = {
        ...this.allSettings,
        ...this.generalSettings,
      }
      try{
        await AdminService.UpdateAdminSettings(newSettings as AdminSetting)
      }      
      catch(error){
        const message = error?.error?.data?.message
        const notification = {
          title: 'Error',
          text: message,
          icon: 'error',
          color: 'error',
          timeout: 0,
          visible: true,
        }
        this.notification = {
          ...this.notification,
          ...notification,
        }
      }
     
      await this.fetchSettings()
      this.saving = false
    }
  }
}

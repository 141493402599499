import store from '@/Common/store'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import GenericResponse from '@/Common/interfaces/GenericResponse'
import ArrivalService from '@/Common/services/ArrivalService'
import ContactEntitlementReportRequest from '../interfaces/ContactEntitlementReportRequest'
import CustomerEntitlementsReportRequest from '../interfaces/CustomerEntitlementsReportRequest'

class ReportService extends ArrivalService {
  public static async GetCustomerReport(request: ContactEntitlementReportRequest | null): Promise<any> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/report/customer?startDate=${request?.startDate.toDateString()}&endDate=${request?.endDate.toDateString()}&locationId=${
          request?.locationId
        }&includePrintableMedia=${request?.includePrintableMedia}&isCsvExport=${request?.isCsvExport}&includeDetails=${
          request?.includeDetails
        }`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: request,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async GetCustomerReportStatus(): Promise<any> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/report/customer/status`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async ReRunCustomerReport(key: string): Promise<any> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'GET',
        url: `${baseUrl}/api/export/Customers/Process/${key}`,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }

  public static async GetCustomerEntitlementsReport(request: CustomerEntitlementsReportRequest | null): Promise<any> {
    let result: any = null
    try {
      const baseUrl = store.getters['settings/baseUrl']
      const { data, status } = await this.http({
        method: 'POST',
        url: `${baseUrl}/api/report/customer/entitlements`,
        headers: {
          Authorization: `Bearer ${store.getters['oidcStore/oidcAccessToken']}`,
        },
        data: request,
      })

      if (data && status === 200) {
        result = data
      }
    } catch (error) {
      const serviceError: ServiceError = {
        error: error.response as object,
        flag: false as boolean,
        generalError: 'An error happened' as string,
      }
      throw serviceError
    }

    return result
  }
}

export default ReportService

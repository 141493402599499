




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'
import LanguageSetting from '../interfaces/LanguageSetting'
import LanguageSettingsSchema from '../schemas/languageSettings'
import PassCheckerSettingsSchema from '../schemas/passCheckerSettings'
import BLELanguageSettingsSchema from '../schemas/bleLanguageSettings'
import merge from 'lodash.merge'

@Component
export default class AdminLanguage extends Vue {
  @Getter('admin/settings') settings
  @Action('admin/fetchAdminSettings')

  // Data Elements
  public fetchAdminSettings: any
  public updating: boolean = false
  public fetching: boolean = false
  public adminSettings: AdminSetting | null = null

  public overwriteValues: boolean = false

  public confirmUpdate: boolean = false

  public defaultLanguage: any = null
  public valid: any = null

  public formOptions: any = {
    rootDisplay: 'expansion-panels',
  }

  public schema: any = LanguageSettingsSchema
  public passCheckerSettingsSchema: any = PassCheckerSettingsSchema
  public bleLanguageSchema: any = BLELanguageSettingsSchema
  public combinedLangSchemaProperties: any = null

  // Life Cycle
  public mounted() {
    if (!this.settings) {
      this.getSettings()
    } else {
      this.adminSettings = {
        ...this.settings,
        languageSettings: JSON.parse(this.settings.languageSettings),
      }
    }

    const hasPassChecker = this.$store.getters.hasFeature('PassChecker')
    const hasBLEConnect = this.$store.getters.hasFeature('BLEConnect')
    const has1Risk = this.$store.getters.hasFeature('1Risk')
  }

  get hasBLEConnect() {
    return this.$store.getters.hasFeature('BLEConnect')
  }

  get combinedLangSchema(): any {
    const hasPassChecker = this.$store.getters.hasFeature('PassChecker')
    const hasBLEConnect = this.$store.getters.hasFeature('BLEConnect')
    const combinedLanguageSchema = this.schema

    const bleProperties = hasBLEConnect ? this.bleLanguageSchema.properties : {}
    const passCheckerProperties = hasPassChecker ? this.passCheckerSettingsSchema.properties : {}

    return {
      ...this.schema,
      properties: {
        ...this.schema.properties,
        ...bleProperties,
        ...passCheckerProperties,
      },
    }
  }

  public async fetchDefaultSettings() {
    const fetchHeaders = new Headers()
    fetchHeaders.append('pragma', 'no-cache')
    fetchHeaders.append('cache-control', 'no-cache')

    const fetchInit = {
      methood: 'GET',
      headers: fetchHeaders,
    }

    this.fetching = true
    const fetchRequest = new Request('/defaults/default-language.json')

    const response = await fetch(fetchRequest, fetchInit)
    if (response.ok) {
      const setting: LanguageSetting = await response.json()
      const lang = setting as unknown
      const defaultLanguage = lang as {}
      if (defaultLanguage) {
        const currentLanguage = this.adminSettings?.languageSettings as {}
        const newLanguage = this.overwriteValues
          ? { ...currentLanguage, ...defaultLanguage }
          : merge(lang, currentLanguage)
        this.adminSettings = {
          ...(this.adminSettings as AdminSetting),
          languageSettings: newLanguage,
        }
      }
    }
    this.fetching = false
  }

  public async getSettings() {
    const settings = await this.fetchAdminSettings()
    const newSettings = {
      ...(settings as AdminSetting),
      languageSettings: JSON.parse(settings.languageSettings),
    }
    this.adminSettings = newSettings
  }

  private async updateSettings() {
    this.confirmUpdate = false
    if (this.valid && this.adminSettings) {
      this.updating = true
      const newLangSettings = {
        ...this.adminSettings,
        languageSettings: JSON.stringify(this.adminSettings.languageSettings),
      }
      await AdminService.UpdateAdminSettings(newLangSettings)
      this.getSettings()
      this.updating = false
    }
  }
}

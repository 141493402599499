













































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Activity from '../../Activity/interfaces/Activity'
import Location from '../../Location/interfaces/location'
import LocationActivity from '../../Location/interfaces/LocationActivity'
import LocationService from '../../Location/services/LocationService'

@Component
export default class AdminLocations extends Vue {
  @Action('location/fetchLocations')
  private fetchLocations: any
  @Getter('location/locations')
  private locations?: []

  @Action('activity/fetchActivities')
  private fetchActivities: any
  @Getter('activity/activities') activities

  private selectedLocations: [] = []
  private currentLocation: Location | null = null
  private currentLocationAvailableActivities: Activity[] = []
  private newLocationDefaultActivityname: string = ''

  @Watch('selectedLocations')
  onSelectedLocationsChanged(value: Location[] | null) {
    if (value && value.length > 0) {      
      const firstValue = value[0]
      this.currentLocation = {
        ...firstValue,
      }
    } else {
      this.currentLocation = null
    }
  }

  private locationSchema = {
    properties: {
      id: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      isEnabled: {
        title: 'Is Enabled',
        type: 'boolean',
        'x-display': 'switch',
      },
      isDefault: {
        title: 'Is Default',
        type: 'boolean',
        'x-display': 'switch',
      },
      isEditProfileLocation: {
        title: 'Is Edit Profile Location',
        type: 'boolean',
        'x-display': 'switch',
      },
      usePosTransactionsAndOrders: {
        title: 'Use POS Transactions (RTP)',
        type: 'boolean',
        'x-display': 'switch',
      },
      activityLocations: {
        type: 'array',
        readOnly: true,
        'x-display': 'hidden',
      },
      defaultActivityId: {
        type: 'number',
        readOnly: true,
        'x-display': 'hidden',
      },
      checkInCompleteText: {
        title: 'Check In Complete Text',
        type: 'string',
        'x-display': 'textarea',
      },
    },
  }

  get availableActivities() {
    return this.activities
  }

  public newLocation: Location = {
    id: 0,
    name: '',
    description: '',
    defaultActivityId: 0,
    isEnabled: true,
    activityLocations: null,
    isDefault: false,
    usePosTransactionsAndOrders: false,
    checkInCompleteText: '',
    isEditProfileLocation: false,
  }

  public clearedLocation = { ...this.newLocation }

  public locationActivity: LocationActivity = {
    id: 0,
    activityId: 0,
    locationId: 0,
  }

  public created() {
    if (!this.locations) {
      this.getLocations()
    }

    if (!this.activities) {
      this.getActivities()
    }
  }

  public getActivities() {
    this.fetchActivities()
  }

  public async getLocations() {
    await this.fetchLocations()
    if (this.currentLocation && this.locations) {
      const thisLocation = this.locations.find((l: Location) => l.id == this.currentLocation!.id)
      if (thisLocation) {
        this.currentLocation = thisLocation
      }
    }
  }

  public viewLocationDetails(location: Location) {
    this.currentLocation = location
    this.currentLocationAvailableActivities = this.activities.filter((elem) => {
      if (location && location.activityLocations) {
        location.activityLocations.find(({ activityId }) => elem.id === activityId)
      }
    })
  }

  public async addLocation() {
    await LocationService.AddLocation(this.newLocation)
    this.getLocations()
    this.newLocation = { ...this.clearedLocation }
  }

  public async updateLocation() {
    if (this.currentLocation) {
      await LocationService.UpdateLocation(this.currentLocation)
      this.getLocations()
    }
  }

  public async addLocationActivity() {
    if (this.currentLocation) {
      this.locationActivity.locationId = this.currentLocation.id as number
      await LocationService.AddLocationActivity(this.locationActivity)
      this.getLocations()
    }
  }

  public async removeLocation() {
    if (this.currentLocation) {
      await LocationService.RemoveLocation(this.currentLocation.id)
      this.getLocations()
      this.selectedLocations = []
    }
  }

  public async removeLocationActivity(locationId: number, locationActivityId: number) {
    await LocationService.RemoveLocationActivity(locationId, locationActivityId)
    this.getLocations()
  }

  get locationsTableHeaders() {
    return [
      { text: 'Id', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Is Enabled', value: 'isEnabled', align: 'center', sortable: false },
      { text: 'Is Default', value: 'isDefault', align: 'center', sortable: false },
      { text: 'Is Edit Profile Location', value: 'isEditProfileLocation', align: 'center', sortable: false },
      { text: 'Use POS', value: 'usePosTransactionsAndOrders', align: 'center', sortable: false },
      { text: 'Modified Date', value: 'modifiedDate', align: 'center' },
      { text: 'Status', value: 'hasDefaultActivity', sortable: false, align: 'center' },
    ]
  }
}

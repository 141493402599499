


























import { Action, Getter } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import SlimCropper from './SlimCropper.vue'
import TaskBase from './TaskBase'

@Component({
  components: {
    SlimCropper,
  },
})
class PhotoTask extends TaskBase {
  // Store items
  @Action('trackEvent') trackEvent
  @Getter('oidcStore/oidcUser') user: any
  @Getter('task/currentTask') currentTask

  // Data Values
  public photoUploading: boolean = false
  private currentContactTask: ContactTask | null = null

  // Methods

  public slimOptions(contactTask) {
    return {
      ratio: '1:1',
      forceSize: '400,400',
      service: `/api/photo/Contact/Upload?contactId=${contactTask.contact.contactId}`,
      download: false,
      remove: false,
      upload: false,
      edit: false,
      instantEdit: true,
      push: true,
      didConfirm: this.onPhotoConfirmed,
      didUpload: this.onPhotoUpload,
      initialImage: ``,
      label: '',
    }
  }

  private addPhotoEvent() {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Add Photo',
      noninteraction: false,
      // TODO: Add a label/value attribute to indicate new or photo update
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  public onPhotoConfirmed() {
    this.photoUploading = true
  }

  public onPhotoUpload() {
    if (this.currentContactTask) {
      this.currentContactTask.isComplete = true
      this.addPhotoEvent()
      Vue.set(this.currentContactTask, 'isComplete', true)
    }
    this.photoUploading = false
  }

  private photoStyle(contact) {
    return `background-image: url(data:image/jpeg;base64,${contact.photo})`
  }

  private initials(contact) {
    let initials = ''
    initials += contact.firstName.charAt(0)
    initials += contact.lastName.charAt(0)
    return initials
  }

  private generateHash() {
    // This random hash value is used to trigger an refresh of the token when requested
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  private editPhoto(contactTask) {
    const newHash = this.generateHash()
    Vue.set(contactTask.contact, 'photoHash', newHash)
    this.currentContactTask = contactTask
  }
}

export default PhotoTask




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'
import ThemeSetting from '../interfaces/ThemeSetting'
import ThemeSettingsSchema from '../schemas/themeSettings'
import BLESettingsSchema from '../schemas/bleThemeSettings'
import merge from 'lodash.merge'

@Component
export default class AdminTheme extends Vue {
  @Getter('admin/settings') settings
  @Action('admin/fetchAdminSettings')
  private fetchAdminSettings: any

  // Data Elements
  public adminSettings: AdminSetting | null = null

  public overwriteValues: boolean = false

  public confirmUpdate: boolean = false

  public valid: any = false
  public updating: boolean = false
  public fetching: boolean = false

  public formOptions: any = {
    rootDisplay: 'expansion-panels',
  }

  private schema: any = ThemeSettingsSchema
  private bleSchema: any = BLESettingsSchema

  get combinedSettingsSchema(): any {
    const hasBLEConnect = this.$store.getters.hasFeature('BLEConnect')

    if (hasBLEConnect) {
      const combinedSchema = {
        ...this.schema,
        properties: {
          theme: {
            properties: {
              ...this.schema.properties.theme.properties,
              ...this.bleSchema.properties,
            },
          },
        },
      }
      return combinedSchema
    }

    return this.schema
  }

  // Computed Elements
  get themeSettings() {
    if (this.adminSettings) {
      return this.adminSettings.themeSettings
    } else {
      return null
    }
  }

  // Life Cycle
  public async mounted() {
    if (!this.settings) {
      await this.getSettings()
    }
    this.adminSettings = {
      ...(this.settings as AdminSetting),
    }
    this.adminSettings = {
      ...(this.adminSettings as AdminSetting),
      themeSettings: JSON.parse(this.settings.themeSettings),
    }
  }

  public async fetchDefaultSettings() {
    const fetchHeaders = new Headers()
    fetchHeaders.append('pragma', 'no-cache')
    fetchHeaders.append('cache-control', 'no-cache')

    const fetchInit = {
      methood: 'GET',
      headers: fetchHeaders,
    }

    const fetchRequest = new Request('/defaults/default-theme.json')

    this.fetching = true
    const response = await fetch(fetchRequest, fetchInit)
    if (response.ok) {
      const defaultThemeSettings: ThemeSetting = await response.json()
      const currentThemeSettings = this.adminSettings?.themeSettings as {}
      const newThemeSettings = this.overwriteValues
        ? { ...currentThemeSettings, ...defaultThemeSettings }
        : merge(defaultThemeSettings, currentThemeSettings)
      this.adminSettings = {
        ...(this.adminSettings as AdminSetting),
        themeSettings: newThemeSettings,
      }
    }
    this.fetching = false
    this.overwriteValues = false
  }

  public async getSettings() {
    const settings = await this.fetchAdminSettings()
    const currentThemeSettings = settings.themeSettings ? JSON.parse(settings.themeSettings) : {}
    console.log('RETRIEVED:', currentThemeSettings)
    const newSettings = {
      ...settings,
      themeSettings: currentThemeSettings,
    }
    this.adminSettings = newSettings
  }

  public async updateSettings() {
    this.confirmUpdate = false
    if (this.valid && this.adminSettings) {
      this.updating = true
      const newThemeSettings = {
        ...this.adminSettings,
        themeSettings: JSON.stringify(this.adminSettings.themeSettings),
      }
      await AdminService.UpdateAdminSettings(newThemeSettings)
      this.getSettings()
      this.updating = false
    }
  }
}

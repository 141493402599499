














import Navigation from './Common/components/Navigation.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { mapActions } from 'vuex'
import AppHeader from './Common/components/AppHeader.vue'
import AppFooter from './Common/components/AppFooter.vue'
import AppLoader from './Common/components/AppLoader.vue'
import store from './Common/store/index'

@Component({
  components: {
    Navigation,
    AppHeader,
    AppFooter,
    AppLoader,
  },
})
export default class App extends Vue {
  @Action('location/fetchCurrentLocation') fetchCurrentLocation
  @Getter('settings/settings') appSettings
  @Getter('settings/themeSettings') themeSettings
  @Getter('settings/languageSettings') languageSettings
  @Getter('settings/theme') theme
  @Getter('browserInfo') browserInfo
  @State('pageLoading') pageLoading

  created() {
    store.dispatch('oidcStore/getOidcUser')
  }

  get appClasses() {
    return this.appSettings.limitToMobile ? 'limit-to-mobile' : null
  }

  get appPageStyle() {
    const styles: string[] = []
    if (
      this.theme &&
      this.theme.general &&
      this.theme.general.backgroundImageUrl &&
      this.theme.general.overlayBackgroundWithGradient
    ) {
      let linearGradient = ``
      if (this.theme.general.overlayBackgroundWithGradient === 'Dark') {
        linearGradient = `linear-gradient(180deg,#000000 0%,rgba(0, 0, 0, 0.4) 100%,rgba(0, 0, 0, 0.35) 70.31%,rgba(0, 0, 0, 0) 100%),`
      } else if (this.theme.general.overlayBackgroundWithGradient === 'Light') {
        linearGradient = `linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%),`
      }
      const background = `background: ${linearGradient}url('${this.theme.general.backgroundImageUrl}'), #222;`
      const backgroundRepeat = `background-repeat: no-repeat;`
      const backgroundSize = `background-size:cover;`
      const backgroundPosition = `background-position: center`
      styles.push(background)
      styles.push(backgroundRepeat)
      styles.push(backgroundSize)
      styles.push(backgroundPosition)
    }

    return styles.join('')
  }
}

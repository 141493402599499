









import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class Navigation extends Vue {
  @Getter('oidcStore/oidcUser')
  private user!: any
  @Action('oidcStore/signOutOidc')
  private signOut: any

  private signOutOidc() {
    this.signOut()
  }
}

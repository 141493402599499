
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class SetLocation extends Vue {
  @Action('location/fetchLocations')
  private fetchLocations: any
  @Action('location/setLocationByName')
  private setWithName: any
  @Action('location/setLocationById')
  private setWithId: any
  @Getter('location/locations') locations

  private setLocationByName(locationName: any) {
    this.setWithName(locationName)
  }

  private setLocationById(locationId: any) {
    this.setWithId(locationId)
  }

  get location() {
    return this.$route.params.locationName
  }

  render() {
    // dont render anything for this
  }

  created() {
    this.setLocation()
  }

  private async setLocation() {
    if (!this.locations) {
      await this.fetchLocations()
    }
    if (parseInt(this.location, 10)) {
      this.setLocationById(this.location)
    } else {
      this.setLocationByName(this.location)
    }
    this.$router.push({ name: 'home' }).catch((failure) => {
      console.log('Failure navigating after setting location:', failure)
    })
  }
}

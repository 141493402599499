
































import { Component, Prop, Model, PropSync, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AxSelect extends Vue {
  @Prop({ type: String, default: null }) label: string | null
  @Prop({ type: String, default: 'value' }) optionKey: string
  @Prop({ type: String, default: 'text' }) optionText: string
  @Prop({ type: String, default: 'Choose an option' }) placeholder: string
  @Prop({ type: Array, default: null }) selectOptions: [] | null
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: String, default: null }) size: string | null
  @Model('update:value', { type: Number, required: false }) readonly value!: number | null
  @PropSync('value', { default: null, required: false }) selectedOptionValue!: number | null

  @Watch('selectedOptionValue')
  onSelectedOptionChanged(value: any) {
    if (this.selectOptions) {
      const selectedOption = this.selectOptions.find((opt) => opt[this.optionKey] === value)
      this.$emit('select-changed', selectedOption)
    }
  }

  get componentClasses() {
    const classes: string[] = []
    classes.push(`size--${this.size}`)
    return classes.join(' ')
  }
}

import Vuex, { StoreOptions } from 'vuex'

import BrowserInfo from '../interfaces/BrowserInfo'
import GTMEvent from '../interfaces/GTMEvent'
import { RootState } from './types'
import Vue from 'vue'
import { activity } from '@/Activity/store/index'
import { admin } from '@/Admin/store/index'
import { location } from '@/Location/store/index'
import { profile } from '@/Profile/store/index'
import { settings } from '@/Settings/store/index'
import { task } from '@/Task/store/index'

Vue.use(Vuex)

// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
  state: {
    destination: '',
    version: '1.0.0', // a simple property
    pageLoading: false,
    features: [],
    appInsights: null,
    exportKey: '',
    browserInfo: null,
  },
  getters: {
    browserInfo(state) {
      return state.browserInfo
    },
    destination(state) {
      return state.destination
    },
    appInsights(state) {
      return state.appInsights
    },
    features(state) {
      return state.features
    },
    exportKey(state) {
      return state.exportKey
    },
    hasFeature(state) {
      return (featureName) => {
        return !!state.features.find((f) => f === featureName)
      }
    },
  },
  mutations: {
    setBrowserInfo(state, browserInfo) {
      state.browserInfo = browserInfo
    },
    setDestination(state, destination) {
      state.destination = destination
    },
    setAppInsights(state, appInsights) {
      state.appInsights = appInsights
    },
    setPageLoading(state, loading) {
      state.pageLoading = loading
    },
    setFeatures(state, features) {
      state.features = features
    },
    setExportKey(state, exportKey) {
      state.exportKey = exportKey
    },
  },
  actions: {
    trackEvent(context, event: GTMEvent) {
      const theEvent = {
        ...event,
        destination: context.state.destination,
      }
      Vue.gtm.trackEvent(theEvent)
    },
  },
  modules: {
    profile,
    location,
    settings,
    activity,
    task,
    admin,
  },
}

export default new Vuex.Store<RootState>(store)


























  import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ContactTask from '../interfaces/ContactTask'
import goTo from 'vuetify/es5/services/goto'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import SigningData from '../interfaces/SigningData'
import SignWaiver from './SignWaiver.vue'
import OneRiskWaiver from './OneRiskWaiver.vue'
import TaskBase from './TaskBase'
import store from '@/Common/store/index'

@Component({
  components: {
    SignWaiver,
    OneRiskWaiver
  },
})
class WaiverTask extends TaskBase {
  // Store items
  @Action('trackEvent') trackEvent
  @Getter('oidcStore/oidcUser') user: any
  @Getter('task/currentTask') currentTask
  @Getter('settings/languageSettings') language
  @Getter('settings/oneRiskSettings') oneRiskSettings
  @Mutation('setPageLoading') setPageLoading

  // Data Elements
  public showSignatureModal: boolean = false
  public currentContactTaskIndex: number = 0
  public signingWaiver: boolean = false
  public processingOneRisk: boolean = false

  // Computed Data Elements
  get hasOneRisk() {
    if (this.currentTask.externalWaiverId) {
      return true
    }
    else {
      return false
    }
  }

  get waiverTasks() {
    if (this.currentTask.consolidateWaivers) {
      return this.contactTasks
    } else {
      return [this.currentContactTask]
    }
  }

  get currentContactTask() {
    if (this.contactTasks && this.contactTasks.length > 0) {
      return this.contactTasks[this.currentContactTaskIndex]
    } else {
      this.currentContactTaskIndex = 0
      this.$emit('complete-task')
    }
  }

  get todaysDate(): string {
    return new Date().toLocaleString()
  }

  get oneRiskData() {
    // First, since the DOB string may have the Time at the end, we need to isolate the date part
    const dobArray = this.currentContactTask.contact.dateOfBirth.split('T')
    // Then create a formatted date in YYYYMMDD (date string  will always be YYYY-MM-DD)
    const re = /\-/gi;
    const formattedDob = dobArray[0].replace(re,'')
    // 1Risk requires an expiration date in MS
    const expDateArray = this.currentTask.endEffectiveDate.split('T')
    const posExpirationDateMs = new Date(expDateArray).getTime()

    return {
      language: 'en',
      displayFormat: this.oneRiskSettings.dateFormat,
      integration: 'aspenware',
      waivers: [
        {
          waiverId: this.currentTask.externalWaiverId,
          posWaiverId: this.currentTask.posWaiverId,
          pos: this.oneRiskSettings.pointOfSaleSystem,
          posProductId: null,
          posProductCode: null,
          posExpirationDate: posExpirationDateMs,
          guests: [
            {
              posGuestId: this.currentContactTask.contact.contactId,
              firstName: this.currentContactTask.contact.firstName,
              lastName: this.currentContactTask.contact.lastName,
              dob: formattedDob,
              email: null,
              phone: null,
              address: {
                street1: null,
                street2: null,
                country: null,
                city: null,
                state: null,
                postal: null,
              },
            },
          ],
        },
      ],
    }
  }

  // Life Cycle


  // Methods
  private async onSkip() {
    await this.next()
  }

  private signWaiverEvent(waiverTask: ContactTask) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Sign Waiver',
      noninteraction: false,
      label: `${waiverTask.task.name}`,
      value: `${waiverTask.task.posWaiverId}`,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  private async onSaveOneRiskWaiver(signingData: SigningData) {
    this.signingWaiver = true
    this.processingOneRisk = true

    if (signingData) {


      try {
        const customComment = this.generateCustomComment(
          this.currentTask,
          this.contactTasks[this.currentContactTaskIndex]
        )

        // Update the related object field before creating the request
        const signedWaiverObject = {
          ...this.contactTasks[this.currentContactTaskIndex].relatedObject,
          rolAccepted: true,
          signature: signingData.signature,
          signatureDate: this.todaysDate,
          comment: customComment,
          rolText: signingData.waiverHTML,
        }

        this.contactTasks[this.currentContactTaskIndex].relatedObject = signedWaiverObject

        this.signWaiverEvent(this.contactTasks[this.currentContactTaskIndex])

        await this.completeContactTask(this.contactTasks[this.currentContactTaskIndex])

        await this.next()

      } catch (error) {
        alert(`ERROR CALLING API ${error}`)
      }
    }
    else {
      await this.completeContactTask(this.contactTasks[this.currentContactTaskIndex])

      await this.next()
    }
  
    this.signingWaiver = false
    this.processingOneRisk = false
  }

  private async onSaveSignature(signingData: SigningData) {
    this.signingWaiver = true
    if (this.currentTask.consolidateWaivers) {
      this.contactTasks.forEach((ct) => {
        // If no waiverHTML document was sent in the signing data, replace it with the text from the waiver
        if (!signingData.waiverHTML) {
          signingData.waiverHTML = ct.relatedObject.rolText
        }

        const customComment = this.generateCustomComment(this.currentTask, ct)

        // Update the task.relatedObject field before creating the request
        const signedWaiverObject = {
          ...ct.relatedObject,
          rolAccepted: true,
          signature: signingData.signature,
          signatureDate: this.todaysDate,
          comment: customComment,
          rolText: signingData.waiverHTML,
        }
        ct.relatedObject = signedWaiverObject

        this.signWaiverEvent(ct)

        this.completeContactTask(ct)
      })
      await this.next()
    } else {
      try {

        // If no waiverHTML document was sent in the signing data, replace it with the text from the waiver
        if (!signingData.waiverHTML) {
          signingData.waiverHTML = this.contactTasks[this.currentContactTaskIndex].relatedObject.rolText
        }
        const customComment = this.generateCustomComment(
          this.currentTask,
          this.contactTasks[this.currentContactTaskIndex]
        )

        // Update the related object field before creating the request
        const signedWaiverObject = {
          ...this.contactTasks[this.currentContactTaskIndex].relatedObject,
          rolAccepted: true,
          signature: signingData.signature,
          signatureDate: this.todaysDate,
          comment: customComment,
          rolText: signingData.waiverHTML,
        }

        this.contactTasks[this.currentContactTaskIndex].relatedObject = signedWaiverObject

        this.signWaiverEvent(this.contactTasks[this.currentContactTaskIndex])

        await this.completeContactTask(this.contactTasks[this.currentContactTaskIndex])

        await this.next()
      } catch (error) {
        alert('ERROR CALLING API')
      }
    }
    this.signingWaiver = false
  }

  private async next() {
    goTo(0, {
      duration: 100,
      offset: 0,
      easing: 'easeInCubic',
    })
    if (this.currentTask.consolidateWaivers) {
      this.currentContactTaskIndex = 0
      // this.showSignatureModal = false
      this.$emit('complete-task')
    } else {
      if (this.currentContactTaskIndex < this.contactTasks.length - 1) {
        this.currentContactTaskIndex++
      } else {
        this.currentContactTaskIndex = 0
        // this.showSignatureModal = false
        this.$emit('complete-task')
      }
    }
  }

  public closeWaiverModal() {
      //this empty funtion causes a warning.Do we need it?
  }

  private generateCustomComment(task, contactTask) {
    const nVer = navigator.appVersion
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let majorVersion = parseInt(navigator.appVersion, 10)
    let nameOffset = 0
    let verOffset = 0

    // In Opera 15+, the true version is after "OPR/"
    /* tslint:disable */
    if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
      browserName = 'Opera'
      fullVersion = nAgt.substring(verOffset + 4)
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    /* tslint:disable */
    else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browserName = 'Opera'
      fullVersion = nAgt.substring(verOffset + 6)
      /* tslint:disable */
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        fullVersion = nAgt.substring(verOffset + 8)
      }
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    /* tslint:disable */
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browserName = 'Microsoft Internet Explorer'
      fullVersion = nAgt.substring(verOffset + 5)
    }
    // In Chrome, the true version is after "Chrome"
    /* tslint:disable */
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browserName = 'Chrome'
      fullVersion = nAgt.substring(verOffset + 7)
    }
    // In Safari, the true version is after "Safari" or after "Version"
    /* tslint:disable */
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browserName = 'Safari'
      fullVersion = nAgt.substring(verOffset + 7)
      /* tslint:disable */
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        fullVersion = nAgt.substring(verOffset + 8)
      }
    }
    // In Firefox, the true version is after "Firefox"
    /* tslint:disable */
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browserName = 'Firefox'
      fullVersion = nAgt.substring(verOffset + 8)
    }
    // In most other browsers, "name/version" is at the end of userAgent
    /* tslint:disable */
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset)
      fullVersion = nAgt.substring(verOffset + 1)
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if (fullVersion.includes(';')) {
      fullVersion.slice(0, fullVersion.lastIndexOf(';'))
    }
    if (fullVersion.includes(' ')) {
      fullVersion.slice(0, fullVersion.lastIndexOf(' '))
    }

    majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion)
      majorVersion = parseInt(navigator.appVersion, 10)
    }

    let customComment: string = task.customComment ? task.customComment : ''
    customComment = customComment.replace('{SignedDate}', this.todaysDate)
    customComment = customComment.replace('{Signer}', this.user.name)
    customComment = customComment.replace('{BrowserName}', browserName)
    customComment = customComment.replace('{BrowserVersion}', fullVersion)

   if (contactTask.contact && contactTask.contact.age && contactTask.contact.age < 18) {
      customComment =
        `${this.user.name} signed on behalf of minor ${
          this.contactTasks[this.currentContactTaskIndex].contact.firstName
        } ${contactTask.contact.lastName}.` + customComment
    } else {
      customComment =
        ` ${contactTask.contact.firstName} ${contactTask.contact.lastName} signed on their own behalf.` + customComment
    }

    return customComment
  }
}

export default WaiverTask
